export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  ARBITRUM_KOVAN = 144545313136048,
  ARBITRUM_ONE = 42161,
  TEST_NET = 97,
  BSC = 56,
}
