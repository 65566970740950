import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Row, Col, Button, Table, Modal, Input } from 'reactstrap'
import { DetailStake } from 'pages/styled'

import { usePlanetContract, usePulvisContract, useStakeContract } from 'hooks/useContract'
import { Stake } from 'models/stake'
import { BigNumber, ContractTransaction } from 'ethers'
import { convertDate } from 'utils/date'
import { convertCurrency, convertCurrencyPSB, converttoDec, getCurrency } from 'utils/currency'
import { shortenAddress } from 'utils'
import { ToastContainer, toast } from 'react-toastify'
import { PLANET_ADDRESS, PULVIS_ADDRESS, STAKE_ADDRESS } from 'constants/addresses'
import { PSB } from 'constants/tokens'
import { RewardInfo, StakeHistory } from 'models/stakeHistory'
import Loader from 'components/Loader'
import { useWalletModalToggle } from 'state/application/hooks'

export default function Pool() {
  const { account, chainId, active } = useWeb3React()
  const stakeContract = useStakeContract()
  const pulvisContract = usePulvisContract()
  const psbContract = usePlanetContract()
  // init state
  const [modal, setModal] = useState(false)
  const [balancePulv, setBalancePulv] = useState(BigNumber.from(0))
  const [balancePsb, setBalancePsb] = useState(BigNumber.from(0))
  const [amount, setAmount] = useState<BigNumber>()
  const [inputValue, setInputValue] = useState<number>()
  const [error, setError] = useState(false)
  const [listStaking, setlistStaking] = useState<Stake[]>()
  const [itemSelected, setItemSelected] = useState<Stake>()
  const [stakeClaims, setStakeClaims] = useState<RewardInfo[]>()
  const [approveAllowancePsb, setApproveAllowancePsb] = useState<BigNumber>()
  const [approve, setApprove] = useState(false)
  const [loading, setLoading] = useState(false)
  const [stakeHistories, setStakeHistories] = useState<StakeHistory[]>()
  const toggleWalletModal = useWalletModalToggle()

  useEffect(() => {
    // func get price in coingecko api
    fetchData()
    getBalance()
  }, [account, pulvisContract, psbContract, stakeContract])

  const fetchData = async () => {
    try {
      const allPools = (await stakeContract.getActivePools()) as Stake[]
      setlistStaking(allPools)
      if (account) {
        const stakeClaim = await stakeContract.getStakeClaims(account)
        setStakeClaims(stakeClaim)

        const histories = await stakeContract.getStakeHistories(account)
        setStakeHistories(histories)

        const approveContract = await psbContract.allowance(account, stakeContract.address)
        setApproveAllowancePsb(approveContract)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (amount && approveAllowancePsb && approveAllowancePsb.div(BigNumber.from('10').pow(18))?.gte(amount)) {
      setApprove(true)
    } else setApprove(false)
  }, [approveAllowancePsb, amount])

  // func get balance in metamask
  const getBalance = async () => {
    try {
      if (!account) throw new Error('No crypto wallet found. Please install it.')
      const response = await pulvisContract.balanceOf(account)
      setBalancePulv(response)
      const responsePsb = await psbContract.balanceOf(account)
      setBalancePsb(responsePsb)
    } catch (err) {
      console.log(err)
    }
  }
  const getCurrentStated = (id: BigNumber) => {
    if (stakeClaims) {
      const itemExist = stakeClaims.find((t) => t.poolId.eq(id))
      if (itemExist) return itemExist
    }
    if (!account) return undefined
    return undefined
  }

  const getHistoryItem = (poolId: BigNumber) => {
    const historyItems = stakeHistories?.filter((t) => t.poolId.eq(poolId))
    if (!!historyItems?.length) {
      return historyItems[historyItems.length - 1] || ({} as StakeHistory)
    }
    return undefined
  }

  const approveContract = async () => {
    if (!amount || !chainId) return
    let response: ContractTransaction | undefined
    if (itemSelected?.stakeAddress == PLANET_ADDRESS[chainId]) {
      response = await psbContract.approve(STAKE_ADDRESS[chainId], amount.mul(BigNumber.from('10').pow(18)))
    } else if (itemSelected?.stakeAddress == PULVIS_ADDRESS[chainId]) {
      response = await pulvisContract.approve(STAKE_ADDRESS[chainId], amount.mul(BigNumber.from('10').pow(18)))
    }
    setLoading(true)
    if (response) {
      const data = await response.wait()
      if (data.status) {
        toast.success(`Approve success`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          bodyStyle: { zIndex: 9999000000 },
        })
        setApprove(true)
        setLoading(false)
      } else {
        toast.error('error', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setLoading(false)
      }
    }
  }
  const selectItemData = (id: BigNumber) => {
    if (getCurrentStated(id) && getCurrentStated(id)?.amount && getCurrentStated(id)?.amount.gt(0)) {
      setAmount(getCurrentStated(id)?.amount.div(BigNumber.from(10).pow(18)))
      return
    }
  }

  const getStakedDate = (pool?: Stake) => {
    if (!pool) return new Date().toLocaleDateString() + ' UTC'
    const historyItem = getHistoryItem(pool.id)
    if (historyItem) {
      const b = parseFloat(historyItem?.stakeTime?.toString() || '') * 1000 + new Date().getTimezoneOffset() * 60000
      return new Date(b).getHours() + ':' + new Date(b).getMinutes() + ' ' + new Date(b).toLocaleDateString() + ' UTC'
    } else {
      return new Date().getUTCHours() + ':' + new Date().getMinutes() + ' ' + new Date().toLocaleDateString() + ' UTC'
    }
  }

  const getRetemptionDate = (pool?: Stake) => {
    if (!pool) return `07:00 ` + new Date().toLocaleDateString() + ' UTC'
    const historyItem = getHistoryItem(pool.id)
    if (historyItem) {
      const b =
        parseFloat(historyItem?.stakeTime?.toString() || '') * 1000 +
        48 * 1000 * 3600 +
        parseFloat(pool?.redemptionPeriod?.toString() || '') * 1000 * 3600 * 24 +
        parseFloat(pool?.duration?.toString() || '') * 1000 * 3600 * 24
      return `07:00 ` + new Date(b).toLocaleDateString() + ' UTC'
    } else {
      const a =
        new Date().getTime() +
        48 * 1000 * 3600 +
        parseFloat(pool?.redemptionPeriod?.toString() || '') * 1000 * 3600 * 24 +
        parseFloat(pool?.duration?.toString() || '') * 1000 * 3600 * 24
      return `07:00 ` + new Date(a).toLocaleDateString() + ' UTC'
    }
  }

  const getValueDate = (pool?: Stake) => {
    if (!pool) return `07:00 ` + new Date().toLocaleDateString() + ` UTC`
    const historyItem = getHistoryItem(pool.id)
    if (historyItem) {
      const b = parseFloat(historyItem?.stakeTime?.toString() || '') * 1000 + 24 * 1000 * 3600
      return `07:00 ` + new Date(b).toLocaleDateString() + ' UTC'
    } else {
      const a = new Date().getTime() + 24 * 1000 * 3600
      return `07:00 ` + new Date(a).toLocaleDateString() + ` UTC`
    }
  }

  const getInterestDate = (pool?: Stake) => {
    if (!pool) return new Date().toLocaleDateString() + ` UTC`
    const historyItem = getHistoryItem(pool.id)
    if (historyItem) {
      const b =
        parseFloat(historyItem?.stakeTime?.toString() || '') * 1000 +
        48 * 1000 * 3600 +
        parseFloat(pool?.duration?.toString() || '') * 1000 * 3600 * 24
      return `07:00 ` + new Date(b).toLocaleDateString() + ' UTC'
    } else {
      const a =
        new Date().getTime() + 48 * 1000 * 3600 + parseFloat(pool?.duration?.toString() || '') * 1000 * 3600 * 24
      return `07:00 ` + new Date(a).toLocaleDateString() + ` UTC`
    }
  }
  // stake
  const actionStake = async () => {
    if (!amount || !chainId || !itemSelected) return
    else {
      try {
        const response = await stakeContract.stake(itemSelected.id, amount.mul(BigNumber.from('10').pow(18)), {
          nonce: amount,
        })
        setLoading(true)
        const data = await response.wait()
        setLoading(false)
        if (data.status) {
          toast.success(`Stake success`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            bodyStyle: { zIndex: 9999000000 },
          })
          setModal(false)
          setAmount(undefined)
          setItemSelected(undefined)
          fetchData()
          getBalance()
          return
        }
        toast.error(`Stake error`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          bodyStyle: { zIndex: 9999000000 },
        })
      } catch (err: any) {
        toast.error(err.data ? err.data.message : err.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  if (chainId && active) {
    return (
      <>
        <Row>
          <Col>
            <Table style={{ color: 'white' }}>
              <thead>
                <tr style={{ background: 'gold', color: 'black' }}>
                  <th style={{ borderBottom: '1px solid gold' }}>#</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Token</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Annual Percentage Rate</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Locked Date</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Start Date</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Available</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Max Pool</th>
                  <th style={{ borderBottom: '1px solid gold' }}></th>
                </tr>
              </thead>
              <tbody>
                {listStaking &&
                  listStaking.map((v, i) => (
                    <tr style={{ verticalAlign: 'middle', borderBottom: '1px groove gold' }} key={i}>
                      <th scope="row">{i + 1}</th>
                      <th>
                        {chainId && <img src={PSB[chainId].symbol} width={24} alt="" />}{' '}
                        {chainId && PSB[chainId].symbol}
                      </th>
                      <td>
                        {((parseFloat(v.apr.toString()) / parseFloat(v.denominatorAPR.toString())) * 100).toFixed(2) +
                          ` %`}
                      </td>
                      <td>{v.duration.toString()}</td>
                      <td>{convertDate(v.startTime)}</td>
                      <td>{convertCurrency(v.maxPoolStake.sub(v.totalStaked), chainId, v.stakeAddress)} </td>
                      <td>{convertCurrency(v.maxPoolStake, chainId, v.stakeAddress)}</td>
                      <td>
                        {!getCurrentStated(v.id) ? (
                          <Button
                            disabled={loading}
                            style={{
                              border: '1px solid #ffd700',
                              background: '#FAFAFA',
                              color: '#C99400',
                              fontWeight: 500,
                              borderRadius: 0,
                            }}
                            onClick={async () => {
                              setItemSelected(v)
                              setModal(!modal)
                              setError(false)
                              setAmount(undefined)
                            }}
                          >
                            <span>Stake</span>
                          </Button>
                        ) : (
                          <Button
                            style={{
                              border: '1px solid #ffd700',
                              background: '#FAFAFA',
                              color: '#C99400',
                              fontWeight: 500,
                              borderRadius: 0,
                            }}
                            onClick={async () => {
                              selectItemData(v.id)
                              setItemSelected(v)
                              setModal(!modal)
                              setError(false)
                            }}
                          >
                            Detail
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="text-center p-4">
              {listStaking && listStaking.length > 0 ? <></> : <span>No Pool!</span>}
            </div>
          </Col>
        </Row>
        {/* modal detail */}
        <Modal isOpen={modal} toggle={() => setModal(!modal)} style={{ marginTop: '10vh' }}>
          <DetailStake>
            <div className="item">
              <span className="h3 bold">Detail</span>
              <span
                style={{ cursor: 'pointer' }}
                className="boder-gradient"
                onClick={() => itemSelected && window.open(`https://bscscan.com/address/${itemSelected.stakeAddress}`)}
              >
                {itemSelected?.stakeAddress && shortenAddress(itemSelected.stakeAddress.toString())}
              </span>
            </div>

            <div className="title">
              <span>Summary</span>
            </div>
            <div className="item">
              <span className="item-title">Stake Date</span>
              <span className="item-value">{getStakedDate(itemSelected)}</span>
            </div>
            <div className="item">
              <span className="item-title">Value Date</span>
              <span className="item-value">{getValueDate(itemSelected)}</span>
            </div>
            <div className="item">
              <span className="item-title">Interest Period</span>
              <span className="item-value">{itemSelected?.duration.toString() + ` Days`}</span>
            </div>
            <div className="item">
              <span className="item-title">Interest Date</span>
              <span className="item-value">{getInterestDate(itemSelected)}</span>
            </div>
            <div className="item">
              <span className="item-title">Redemption Period</span>
              <span className="item-value">{itemSelected?.redemptionPeriod.toString() + ` Day`}</span>
            </div>
            <div className="item">
              <span className="item-title">Retemption Date</span>
              <span className="item-value">{getRetemptionDate(itemSelected)}</span>
            </div>
            <div className="title">
              <span>Reward</span>
            </div>
            <div className="item">
              <span className="item-title">Est. APR</span>
              <span className="item-value">
                {itemSelected &&
                  (
                    (parseFloat(itemSelected?.apr.toString()) / parseFloat(itemSelected?.denominatorAPR.toString())) *
                    100
                  ).toFixed(2) + ` %`}
              </span>
            </div>
            <div className="item">
              <span className="item-title">Estimated Interest</span>
              <span className="item-value" style={{ color: 'green' }}>
                {itemSelected && amount
                  ? (
                      ((parseFloat(itemSelected?.apr.toString()) /
                        parseFloat(itemSelected?.denominatorAPR.toString())) *
                        parseFloat(amount.toString()) *
                        parseFloat(itemSelected?.duration.toString())) /
                      365
                    ).toFixed(2) +
                    ` ` +
                    getCurrency(chainId, itemSelected.stakeAddress)
                  : `0 `}
              </span>
            </div>
            {itemSelected ? (
              <div>
                {getCurrentStated(itemSelected.id) ? (
                  <div className="">
                    <div className="item">
                      <span className="item-title">Staking Amount</span>
                      <span className="item-value" style={{ color: 'green' }}>
                        {convertCurrency(getCurrentStated(itemSelected.id)?.amount, chainId, itemSelected.stakeAddress)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-around mt-4">
                      <Button onClick={() => setModal(!modal)}>Close</Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="title d-flex justify-content-between">
                      <span>Lock Amount</span>
                      <small>
                        <small className="text-secondary">Available </small>
                        {itemSelected &&
                          convertCurrency(
                            chainId && PLANET_ADDRESS[chainId] == itemSelected.stakeAddress ? balancePsb : balancePulv,
                            chainId,
                            itemSelected.stakeAddress
                          )}
                      </small>
                    </div>
                    <div className="box-amount">
                      <Input
                        value={amount?.toNumber() || undefined}
                        width={'99%'}
                        type="number"
                        min={converttoDec(itemSelected?.minTokenStake ?? BigNumber.from(0))}
                        style={{ background: 'transparent', color: 'white', margin: '10px 0' }}
                        placeholder="Please enter the amount"
                        onChange={(e) => {
                          if (
                            parseFloat(e.target.value) >=
                              converttoDec(itemSelected?.minTokenStake ?? BigNumber.from(0)) &&
                            parseFloat(e.target.value) <=
                              converttoDec(itemSelected?.maxTokenStake ?? BigNumber.from(0)) &&
                            parseFloat(e.target.value) <=
                              converttoDec(
                                chainId && PLANET_ADDRESS[chainId] == itemSelected.stakeAddress
                                  ? balancePsb
                                  : balancePulv
                              )
                          ) {
                            setError(false)
                            try {
                              setAmount(e.target.value ? BigNumber.from(e.target.value) : BigNumber.from(0))
                            } catch (err: any) {
                              setError(true)
                            }
                          } else {
                            setAmount(e.target.value ? BigNumber.from(e.target.value) : BigNumber.from(0))
                            setError(true)
                          }
                        }}
                      />
                      <span
                        className="max-amount"
                        onClick={() => {
                          const data = balancePsb.div(BigNumber.from(10).pow(18))
                          if (itemSelected && itemSelected.maxTokenStake.gt(balancePsb)) {
                            setAmount(data)
                          } else {
                            setAmount(itemSelected?.maxTokenStake.div(BigNumber.from(10).pow(18)))
                          }
                        }}
                      >
                        Max
                      </span>
                      <small hidden={!error} className="text-danger">
                        Quantity must be less than the maximum, balance and larger than the minimum and be an integer
                      </small>
                    </div>
                    <div className="title">
                      <span>Locked Amount Limitation</span>
                    </div>
                    <div className="item">
                      <span className="item-title">Minimum</span>
                      <span className="item-value">
                        {convertCurrency(
                          itemSelected?.minTokenStake ?? BigNumber.from(0),
                          chainId,
                          itemSelected?.stakeAddress
                        )}
                      </span>
                    </div>
                    <div className="item">
                      <span className="item-title">Maximum</span>
                      <span className="item-value">
                        {convertCurrency(
                          itemSelected?.maxTokenStake ?? BigNumber.from(0),
                          chainId,
                          itemSelected?.stakeAddress
                        )}
                      </span>
                    </div>
                    <div className="d-flex justify-content-around mt-4">
                      {!approve ? (
                        <Button
                          disabled={!itemSelected?.isActive || loading}
                          style={{
                            marginTop: 'auto !important',
                            background: 'gold',
                            color: 'black',
                            border: 0,
                            borderRadius: 3,
                            fontWeight: 500,
                          }}
                          onClick={() => {
                            if (!amount || amount == BigNumber.from(0)) setError(true)
                            else if (!error) {
                              approveContract()
                            }
                          }}
                        >
                          {loading ? (
                            <span>
                              <span className="mr-2">
                                <Loader />
                              </span>
                              Approve
                            </span>
                          ) : (
                            <span>Approve</span>
                          )}
                        </Button>
                      ) : (
                        <Button
                          disabled={!itemSelected?.isActive || loading}
                          style={{
                            marginTop: 'auto !important',
                            background: 'gold',
                            color: 'black',
                            border: 0,
                            borderRadius: 3,
                            fontWeight: 500,
                          }}
                          onClick={() => {
                            if (!amount || amount == BigNumber.from(0)) setError(true)
                            else if (!error) {
                              actionStake()
                            }
                          }}
                        >
                          {loading ? (
                            <span className="mr-2">
                              <span className="mr-2">
                                <Loader />
                              </span>
                              Confirm
                            </span>
                          ) : (
                            <span>Confirm</span>
                          )}
                        </Button>
                      )}
                      <Button onClick={() => setModal(!modal)}>Cancel</Button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </DetailStake>
        </Modal>
      </>
    )
  }
  return (
    <Row>
      <Button
        style={{
          width: '40%',
          margin: 'auto',
          borderRadius: 0,
          background: '#ffd700',
          color: 'black',
          fontSize: 24,
        }}
        onClick={toggleWalletModal}
      >
        Connect wallet now
      </Button>
    </Row>
  )
}
