import styled from 'styled-components/macro'
//  define component
export const Box2 = styled.div`
  border-radius: 0;
  position: relative;
  border: 1px solid #ffd700;
  padding: 20px;
  background: ${({ theme }) => theme.bg6};
  ${({ theme }) => theme.mediaWidth.upToSmall`
width:100%;
  `};
`
export const Box = styled.div`
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 48.5%;
  position: relative;
  border: 1px solid #ffd700;
  padding: 20px;
  background: ${({ theme }) => theme.bg6};
  ${({ theme }) => theme.mediaWidth.upToSmall`
width:100%;
  `};
  .title {
    margin: 15px auto;
    border: 1px solid transparent;
    border-radius: 0;
    background-image: linear-gradient(rgb(24, 21, 50), rgb(24, 21, 50)),
      linear-gradient(
        219deg,
        rgba(255, 109, 109, 1) 0%,
        rgba(255, 116, 0, 1) 32%,
        rgba(255, 235, 0, 1) 57%,
        rgba(113, 255, 136, 1) 81%,
        rgba(0, 212, 255, 1) 100%
      );
    background-origin: border-box;
    background-clip: padding-box, border-box;
    padding: 5px 35px;
    text-transform: uppercase;
  }
  img {
    box-shadow: 0px 6px 20px 0px #e7ff81;
    border-radius: 50%;
  }
  .contrast {
    color: #ffd700;
    cursor: pointer;
  }
  p {
    font-size: 14px;
    span {
      line-height: 100%;
    }
  }
  button {
    margin-top: auto !important;
    width: 100%;
    padding: 10px 15px;
    background: gold;
    color: black;
    border: 0px;
    border-radius: 0;
    font-weight: 700;
    margin: 5px;
    text-transform: uppercase;
  }
  // &:after {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 10px;
  //   left: -10px;
  //   border: 1px solid #ffd700;
  //   z-index: -1;
  //   background: ${({ theme }) => theme.bg6};
  // }
  // &:before {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 20px;
  //   left: -20px;
  //   border: 1px solid #ffd700;
  //   z-index: -1;
  //   background: ${({ theme }) => theme.bg6};
  // }
`
export const DetailStake = styled.div`
  background: ${({ theme }) => theme.bg6};
  padding: 30px;
  border-radius: 0;
  border: 1px solid #ffd700;
  .item {
    display: flex;
    justify-content: space-between;
    padding: 3px;
    align-item: center;
  }
  .item-title {
    color: #838383 !important;
  }
  .title {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .boder-gradient {
    padding: 8px 12px;
    border: 1px solid transparent;
    border-radius: 0;
    background-image: linear-gradient(rgb(24, 21, 50), rgb(24, 21, 50)),
      linear-gradient(
        219deg,
        rgba(255, 109, 109, 1) 0%,
        rgba(255, 116, 0, 1) 32%,
        rgba(255, 235, 0, 1) 57%,
        rgba(113, 255, 136, 1) 81%,
        rgba(0, 212, 255, 1) 100%
      );
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
`
