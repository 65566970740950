import { BigNumber } from '@ethersproject/bignumber'

export function convertDate(timestampHex: BigNumber) {
  const timestamp = parseFloat(timestampHex.toString()) * 1000
  if (timestamp > 0)
    return `${
      new Date(timestamp).getUTCHours() < 10
        ? `0${new Date(timestamp).getUTCHours()}`
        : new Date(timestamp).getUTCHours()
    }:${
      new Date(timestamp).getMinutes() < 10 ? `0${new Date(timestamp).getMinutes()}` : new Date(timestamp).getMinutes()
    } ${new Date(timestamp).toLocaleDateString()} UTC`
  return ''
}
