import React from 'react'
import styled from 'styled-components'
import * as Asset from 'assets'
import { useIsDarkMode } from 'state/user/hooks'
import { ExternalLink } from '../../theme'
import { Color, Typography } from 'styles'
import logo from 'assets/app/logo.png'
import { Row, Col, Container } from 'reactstrap'

const InfoWithText = styled.div`
  display: flex;
  flex-direction: column;
  .image {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .image:hover {
    opacity: 0.5;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    display: flex;
    align-items: center;
  }
`
const activeClassName = 'ACTIVE'

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
})<{ isActive?: boolean }>`
  outline: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.3s;
  margin-left: 10px;
  :hover {
    text-decoration: none;
    padding-left: 10px;
  }
`
const Text = styled.p`
  color: ${Color.neutral.gray};
  ${{ ...Typography.fontSize.x20 }};
  margin: 8px 0;
`
const BoldText = styled.h5`
  text-transform: uppercase;
  color: gold;
  margin: 12px 0;
  font-weight: 700;
  font-size: 16px;
`
const Logo = styled.img`
  width: 100%;
  margin-top: 10%;
  padding: 15px;
`

export default function Footer() {
  const darkMode = useIsDarkMode()
  return (
    <Container>
      <Row className="justify-content-between footer">
        <Col xs={12} lg={3}>
          <ExternalLink id={`stake-nav-link`} href={'https://planetsandbox.io/'}>
            <Logo src={logo} alt="logo" className="logo" />
          </ExternalLink>
          <small className="d-block pt-3 text-center">Contact: admin@planetsandbox.io</small>
        </Col>

        <Col xs={12} lg={2}>
          <InfoWithText>
            <BoldText>INTRODUCE</BoldText>
            <StyledExternalLink id={`stake-nav-link`} href={'//planetsandbox.io'}>
              <Text>Website</Text>
            </StyledExternalLink>
            <StyledExternalLink id={`stake-nav-link`} href={'//planetsandbox.gitbook.io'}>
              <Text>Whitepaper</Text>
            </StyledExternalLink>
            <StyledExternalLink id={`stake-nav-link`} href={'//planetsandbox.io/#FAQs'}>
              <Text>FAQs</Text>
            </StyledExternalLink>
            {/* <StyledExternalLink id={`stake-nav-link`} href={'https://musuem.polrare.co'}>
              <Text>Museum</Text>
            </StyledExternalLink> */}
          </InfoWithText>
        </Col>

        <Col xs={12} lg={2}>
          <BoldText>Buy $PSB</BoldText>
          {/* <StyledExternalLink
            id={`stake-nav-link`}
            href={'https://www.dextools.io/app/bsc/pair-explorer/0xcb9abf27b2e7b8706f0daf30855b80421b41e9a9'}
          >
            <Text>Dextool</Text>
          </StyledExternalLink>
          <StyledExternalLink
            id={`stake-nav-link`}
            href={'https://www.dextools.io/app/bsc/pair-explorer/0xcb9abf27b2e7b8706f0daf30855b80421b41e9a9'}
          >
            <Text>Binance</Text>
          </StyledExternalLink> */}
          <StyledExternalLink
            id={`stake-nav-link`}
            href={'//pancakeswap.finance/info/pair/0xcb9abf27b2e7b8706f0daf30855b80421b41e9a9'}
          >
            <Text>PancakeSwap</Text>
          </StyledExternalLink>
          <StyledExternalLink id={`stake-nav-link`} href={'//www.gate.io/trade/PSB_USDT'}>
            <Text>Gate.io</Text>
          </StyledExternalLink>
        </Col>

        <Col xs={12} lg={3}>
          <InfoWithText>
            <BoldText>JOIN OUR COMMUNITY</BoldText>
            <StyledExternalLink id={`stake-nav-link`} href={'//twitter.com/planetsandbox'}>
              <Asset.Twitter className="image" fill={darkMode ? '#fff' : Color.neutral.green} />
              <Text>Twitter</Text>
            </StyledExternalLink>
            <StyledExternalLink id={`stake-nav-link`} href={'//discord.gg/zwpC6EAatV'}>
              <Asset.Discord className="image" fill={darkMode ? '#fff' : Color.neutral.green} />
              <Text>Discord</Text>
            </StyledExternalLink>
            <StyledExternalLink id={`stake-nav-link`} href={'//t.me/planetsandbox'}>
              <Asset.Telegram className="image" fill={darkMode ? '#fff' : Color.neutral.green} />
              <Text>Telegram</Text>
            </StyledExternalLink>
            {/* <StyledExternalLink id={`stake-nav-link`} href={''}>
              <Asset.Instagram className="image" fill={darkMode ? '#fff' : Color.neutral.green} />
              <Text>Instagram</Text>
            </StyledExternalLink> */}
            <StyledExternalLink id={`stake-nav-link`} href={'//www.youtube.com/c/PlanetSandboxOfficial'}>
              <Asset.Youtube className="image" fill={darkMode ? '#fff' : Color.neutral.green} />
              <Text>Youtube</Text>
            </StyledExternalLink>
          </InfoWithText>
        </Col>
      </Row>
      <Row>
        <Text className="text-center p-3"> Copyright © 2021 PlanetSandbox. All rights reserved.</Text>
      </Row>
    </Container>
  )
}
