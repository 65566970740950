import { BigNumber } from '@ethersproject/bignumber'
import { PLANET_ADDRESS, PULVIS_ADDRESS } from 'constants/addresses'

export const localesEN = 'en-US'

export function convertCurrencyPSB(amount: BigNumber) {
  return (parseFloat(amount.toString()) / 1e18).toLocaleString(localesEN) + ' PSB'
}
export function converttoUSD(amount: BigNumber, rate: any) {
  if (!rate) return 0
  return ((parseFloat(amount.toString()) / 1e18) * rate).toLocaleString(localesEN) + ' USD'
}
export function convertCurrencyPULV(amount: BigNumber) {
  return (parseFloat(amount.toString()) / 1e18).toLocaleString(localesEN) + ' PULV'
}
export function convertCurrency(amount?: BigNumber, chainId?: number, address?: string) {
  let currency = ''
  if (!amount) return 0
  if (address && chainId) {
    if (chainId && address == PLANET_ADDRESS[chainId]) {
      currency = 'PSB'
    }
    if (chainId && address == PULVIS_ADDRESS[chainId]) {
      currency = 'PULV'
    }
  }
  return (parseFloat(amount.toString()) / 1e18).toLocaleString(localesEN) + (currency ? ' ' + currency : '')
}

export function getCurrency(chainId?: number, address?: string) {
  let currency = ''
  if (address && chainId) {
    if (chainId && address == PLANET_ADDRESS[chainId]) {
      currency = 'PSB'
    }
    if (chainId && address == PULVIS_ADDRESS[chainId]) {
      currency = 'PULV'
    }
  }
  return currency
}
export function converttoDec(amount: BigNumber) {
  return parseFloat(amount.toString()) / 1e18
}
