import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import icon from 'assets/app/icon.png'
import { Row, Col } from 'reactstrap'
import { Box, Box2 } from 'pages/styled'

import { useStakeContract } from 'hooks/useContract'
import { ArrowUpRight, ArrowDownRight } from 'react-feather'
import { client, Endpoint } from 'api'
import { RouteComponentProps } from 'react-router-dom'
import { PLANET_ADDRESS, PULVIS_ADDRESS } from 'constants/addresses'
import { BigNumber } from '@ethersproject/bignumber'
import { converttoDec, converttoUSD, localesEN } from 'utils/currency'

export default function Staking({ history }: RouteComponentProps) {
  const stakeContract = useStakeContract()
  const [price, setPrice] = useState(0)
  const [pricePulv, setPricePulv] = useState(0)
  const [percent, setPercent] = useState(0)
  const [totalStaked, setTotalStaked] = useState<number>(0)
  const [totalReward, setTotalReward] = useState<number>(0)
  const [totalPSB, setTotalPSB] = useState<BigNumber>(BigNumber.from(0))
  const [totalStakedPSBAmount, setTotalStakedPSBAmount] = useState<number>(0)
  const [totalPULV, setTotalPULV] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    getPrice()
    getInfo()
    const intervalId = setInterval(() => {
      getPrice()
    }, 30000)
    return () => clearInterval(intervalId)
  }, [stakeContract, price])

  const getPrice = async () => {
    try {
      const response = await client.get(Endpoint.PRICE)
      setPrice(response?.data['0x36bfbb1d5b3c9b336f3d64976599b6020ca805f1'].usd)
      setPercent(response?.data['0x36bfbb1d5b3c9b336f3d64976599b6020ca805f1'].usd_24h_change)
      const responsePulv = await client.get(Endpoint.PRICEPULV)
      setPricePulv(response?.data['0xD59896C6F17F62BB584BBdaAAb8d0E09fDDf691e'].usd)
    } catch (err) {
      console.log(err)
    }
  }
  // get all info in stakeContract
  const getInfo = async () => {
    try {
      const pulvAddress = '0xD59896C6F17F62BB584BBdaAAb8d0E09fDDf691e'
      const psbAddress = '0x36bfBb1d5B3C9b336f3D64976599B6020cA805F1'
      const totalStakedPSB = await stakeContract.getStakedAmount(psbAddress)
      console.log('totalStakedPSB: ', totalStakedPSB)
      setTotalStakedPSBAmount(parseFloat(totalStakedPSB.toString()) / 1e18)
      const totalStakedPulv = await stakeContract.getStakedAmount(pulvAddress)
      const total1 = (parseFloat(totalStakedPSB.toString()) / 1e18) * price
      const total2 = (parseFloat(totalStakedPulv.toString()) / 1e18) * pricePulv
      const totalStakeValue = total1 + total2
      setTotalStaked(totalStakeValue)
      const totalPSB = await stakeContract.getRewardAmount(psbAddress)
      setTotalPSB(totalPSB)
      const totalPULV = await stakeContract.getRewardAmount(pulvAddress)
      setTotalPULV(totalPULV)
      const rewardpsb = (parseFloat(totalPSB.toString()) / 1e18) * price
      const rewardpulv = (parseFloat(totalPULV.toString()) / 1e18) * pricePulv
      setTotalReward(rewardpsb + rewardpulv)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getInfo()
  }, [stakeContract, price, pricePulv])

  return (
    <>
      <Row>
        <Col
          style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', justifyItems: 'start' }}
          xs={12}
        >
          <Box>
            <img className="my-2" width="48px" src={icon} alt="" />
            <span className="title">Stake</span>
            <p>
              Welcome to the PlanetSandbox staking protocol. Here you are able to benefit from{' '}
              <span className="contrast">staking tokens</span> and
              <span className="contrast"> receiving PSB</span> rewards.
            </p>
            <button onClick={() => history.push('/pool')}>POOLs</button>
          </Box>
          <Box>
            <img className="my-2" width="48px" src={icon} alt="" />
            <span className="title">Rewards</span>
            <p>
              Staking rewards are distributed as<span className="contrast"> PSB</span> or{' '}
              <span className="contrast">PULV</span> tokens.
            </p>
            <button onClick={() => history.push('/reward')}>Rewards</button>
          </Box>
        </Col>
      </Row>

      <Row>
        <Col
          className="mb-4 mt-3 col-reward"
          style={{ display: 'flex', flexWrap: 'wrap', justifyItems: 'start' }}
          xs={12}
        >
          <Box2 className="me-3 box-reward" style={{ width: '35%', textAlign: 'center', padding: 30 }}>
            <h6>PSB Rewards</h6>
            <div
              style={{
                border: '5px solid red',
                borderRadius: '50%',
                width: 150,
                height: 150,
                margin: '15px auto',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                }}
              >
                Total Distributed
                <h5 className="mt-2">{totalReward.toLocaleString(localesEN) || 0} USD</h5>
              </div>
            </div>
            <div className="d-flex justify-content-between text-muted">
              <span>
                PSB Value: <br /> <strong className="h5 text-light">{totalPSB && converttoUSD(totalPSB, price)}</strong>{' '}
              </span>
              <span>
                PULV Value: <br />
                {/* <strong className="h5 text-light">{totalPULV && converttoUSD(totalPULV, pricePulv)}</strong> */}
                <strong className="h5 text-light">0 USD</strong>
              </span>
            </div>
          </Box2>
          <div>
            <Box2>
              <div>
                <img className="me-2" width="24px" height="24px" src={icon} alt="" />
                <span>PSB Price</span>
                <strong className="mt-2 mx-3 h5">
                  <span>$ </span>
                  {price?.toFixed(3) || 0}
                </strong>
                {percent > 0 ? (
                  <span className="mt-2" style={{ color: 'green' }}>
                    <ArrowUpRight />
                    {percent?.toFixed(2) || 0}
                    <span>% last 24h</span>
                  </span>
                ) : (
                  <span className="mt-2" style={{ color: 'red' }}>
                    <ArrowDownRight />
                    {percent?.toFixed(2) || 0}
                    <span>% last 24h</span>
                  </span>
                )}
              </div>
            </Box2>
            <Box2 className="mt-3 text-center">
              <h6 className="mt-4">Total Value Staked</h6>
              <span className="h3 my-4 d-block">{totalStaked.toLocaleString(localesEN)} USD</span>
              <h6 className="mt-4">PSB Amount Staked</h6>
              <span className="h6 my-2 d-block">{totalStakedPSBAmount.toLocaleString(localesEN)} PSB</span>
            </Box2>
          </div>
        </Col>
      </Row>

      <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
        <button
          className="btn-link"
          onClick={() => window.open('//staking.planetsandbox.io/REP-Planet-Sandbox-(Staking)-2021-12-09.pdf')}
        >
          Certik Audit
        </button>
        <button className="btn-link" onClick={() => window.open('https://planetsandbox.gitbook.io/planetsandbox-game')}>
          Whitepaper
        </button>
        <button
          className="btn-link"
          onClick={() =>
            window.open('https://planetsandbox.medium.com/planet-sandbox-reveals-psb-staking-program-1ca5b3acdd7a')
          }
        >
          Staking Guide
        </button>
      </Row>
    </>
  )
}
