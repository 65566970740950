import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Web3ReactManager from '../components/Web3ReactManager'
import ErrorBoundary from '../components/ErrorBoundary'
import styled from 'styled-components/macro'
import background from '../assets/images/background.png'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Staking from './staking'
import Pool from './pool'
import Reward from './reward'
// import Vesting from './vesting'
import { Container, Row, Button } from 'reactstrap'
import { useWeb3React } from '@web3-react/core'
import { Box } from './styled'
import { useWalletModalToggle } from 'state/application/hooks'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 85vh;
  height: 100%;
  padding-top: 90px;
  padding-bottom: 90px;
  flex: 1;
  background: url(${background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0px 15px;
    margin-top: 100px;
    margin-bottom: 70px;
    height: 100vh%;
  `};
  .btn-link {
    width: auto;
    background: ${({ theme }) => theme.bg6};
    border: 1px solid #ffd700;
    padding: 10px 20px;
    margin-left: 12px;
    text-decoration: none;
    color: snow;
  }
`
const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 3;
`

export default function App() {
  const { active, chainId } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  return (
    <ErrorBoundary>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <BodyWrapper className="body-wrapper">
        <Web3ReactManager>
          <>
            {active && chainId != 97 && chainId != 56 ? (
              // error network
              <Container style={{ zIndex: 1 }}>
                <Row style={{ minHeight: '100vh' }}>
                  <Box
                    style={{
                      margin: 'auto',
                      marginTop: 40,
                      borderRadius: 0,
                    }}
                  >
                    <h3>Wrong Network</h3>
                    <small>Please switch your wallet network to BSC Mainnet to use the app</small>
                  </Box>
                </Row>
              </Container>
            ) : (
              <Container style={{ zIndex: 2, paddingTop: 50 }}>
                <Switch>
                  <Route exact strict path="/" component={Staking} />
                  <Route path="/staking" component={Staking} />
                  <Route path="/pool" component={Pool} />
                  <Route path="/reward" component={Reward} />
                  {/* <Route path="/vesting" component={Vesting} /> */}
                </Switch>
              </Container>
            )}
          </>
        </Web3ReactManager>
        <div
          style={{
            position: 'absolute',
            inset: 0,
            background: `linear-gradient(180deg, #1A1A1A 36.89%, rgba(26, 26, 26, 0) 114.31%)`,
          }}
        ></div>
      </BodyWrapper>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999999 }}
      />
      <Footer />
    </ErrorBoundary>
  )
}
