import React, { useEffect, useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { useWeb3React } from '@web3-react/core'
import { useStakeContract } from 'hooks/useContract'
import { DetailStake } from 'pages/styled'
import { Row, Col, Button, Table, Modal } from 'reactstrap'
import { convertDate } from 'utils/date'
import { convertCurrency, convertCurrencyPSB, convertCurrencyPULV } from 'utils/currency'
import { ToastContainer, toast } from 'react-toastify'
import { RewardInfo, StakeHistory } from 'models/stakeHistory'
import { Stake } from 'models/stake'
import { shortenAddress } from 'utils'
import Loader from 'components/Loader'
import { PLANET_ADDRESS } from 'constants/addresses'
import { useWalletModalToggle } from 'state/application/hooks'

export default function Reward() {
  const stakeContract = useStakeContract()
  const { account, chainId, active } = useWeb3React()
  // initState
  const [itemSelected, setItemSelected] = useState<RewardInfo>()
  const [pool, setPool] = useState<Stake>()
  const [listStaking, setlistStaking] = useState<Stake[]>()
  // init state
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [unstake, setUnstake] = useState(false)
  const [lsStake, setlsStake] = useState<RewardInfo[]>()
  const [stakeHistories, setStakeHistories] = useState<StakeHistory[]>()
  const toggleWalletModal = useWalletModalToggle()

  useEffect(() => {
    fetData()
  }, [account])
  const fetData = async () => {
    try {
      if (!account) throw new Error('No crypto wallet found. Please install it.')
      const response = await stakeContract.getStakeClaims(account)
      setlsStake(response)

      const histories = await stakeContract.getStakeHistories(account)
      setStakeHistories(histories)

      const allPools = (await stakeContract.getAllPools()) as Stake[]
      setlistStaking(allPools)
    } catch (err) {
      console.log(err)
    }
  }
  const getHistoryItem = (poolId: BigNumber) => {
    const historyItems = stakeHistories?.filter((t) => t.poolId.eq(poolId))
    if (!!historyItems?.length) {
      return historyItems[historyItems.length - 1] || ({} as StakeHistory)
    }
    return {} as StakeHistory
  }

  const getPoolItem = (poolId: BigNumber) => {
    const item = listStaking?.find((t) => t.id.eq(poolId))
    return item || ({} as Stake)
  }

  const getRetemptionDate = (pool?: Stake) => {
    if (!pool || !pool.id) return new Date().toLocaleDateString() + ` UTC`
    const historyItem = getHistoryItem(pool.id)
    if (historyItem) {
      const a =
        parseFloat(historyItem?.stakeTime?.toString() || '') * 1000 +
        48 * 1000 * 3600 +
        parseFloat(pool?.redemptionPeriod?.toString() || '') * 1000 * 3600 * 24 +
        parseFloat(pool?.duration?.toString() || '') * 1000 * 3600 * 24
      return `07:00 ` + new Date(a).toLocaleDateString() + ` UTC`
    }
    return new Date().toLocaleDateString() + ` UTC`
  }

  // func get balance in metamask
  const Claim = async (id: BigNumber) => {
    try {
      const response = await stakeContract.withdraw(id)
      setLoading(true)
      const data = (await response.wait()) as any
      setLoading(false)
      if (data && data.status) {
        toast.success(`Claim success`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          bodyStyle: { zIndex: 9999000000 },
        })
        return
      } else {
        toast.error('Claim error', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          bodyStyle: { zIndex: 9999000000 },
        })
      }
    } catch (err: any) {
      toast.error(err.data ? err.data.message : err.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        bodyStyle: { zIndex: 9999000000 },
      })
      setLoading(false)
    }
  }
  // func get balance in metamask
  const Unstack = async (id: BigNumber) => {
    try {
      const response = await stakeContract.unStake(id)
      setLoading(true)
      const data = await response.wait()
      setLoading(false)
      if (data && data.status) {
        toast.success(`Unstack success`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          bodyStyle: { zIndex: 9999000000 },
        })
        setUnstake(false)
        fetData()
      }
    } catch (err: any) {
      toast.error(err.data ? err.data.message : err.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        bodyStyle: { zIndex: 9999000000 },
      })
    }
  }

  if (chainId && active) {
    return (
      <>
        <Row>
          <Col>
            <Table style={{ color: 'white' }}>
              <thead>
                <tr style={{ background: 'gold', color: 'black' }}>
                  <th style={{ borderBottom: '1px solid gold' }}>#</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Stake Time</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Lock Period</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Redemption Period</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Staked</th>
                  <th style={{ borderBottom: '1px solid gold' }}>Reward</th>
                  <th style={{ borderBottom: '1px solid gold' }}></th>
                </tr>
              </thead>
              <tbody>
                {lsStake &&
                  lsStake.map((v, i) => (
                    <tr style={{ verticalAlign: 'middle', borderBottom: '1px groove gold' }} key={i}>
                      <th scope="row">{i + 1}</th>
                      <td>
                        <span className="item-value">
                          {getHistoryItem(v.poolId) &&
                            getHistoryItem(v.poolId).stakeTime &&
                            convertDate(getHistoryItem(v.poolId).stakeTime)}
                        </span>
                      </td>
                      <td>
                        <span className="item-value">{getPoolItem(v.poolId)?.duration?.toString() + ` Days`}</span>
                      </td>
                      <td>
                        <span className="item-value">{getRetemptionDate(getPoolItem(v.poolId))}</span>
                      </td>
                      <td>{convertCurrency(v.amount, chainId, v.stakeAddress)}</td>
                      <td>{convertCurrency(v.claimableReward, chainId, v.stakeAddress)}</td>
                      <td>
                        <Button
                          style={{
                            border: '1px solid #ffd700',
                            background: '#FAFAFA',
                            color: '#C99400',
                            fontWeight: 500,
                            borderRadius: 0,
                            marginLeft: 10,
                          }}
                          onClick={async () => {
                            setItemSelected(v)
                            Claim(v.poolId)
                          }}
                        >
                          Claim
                        </Button>
                        <Button
                          style={{
                            background: 'gold',
                            color: 'black',
                            fontWeight: 500,
                            borderRadius: 0,
                            marginLeft: 10,
                          }}
                          onClick={async () => {
                            setItemSelected(v)
                            setUnstake(!unstake)
                          }}
                        >
                          Unstake
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="text-center p-4">{lsStake && lsStake.length > 0 ? <></> : <span>No Reward!</span>}</div>
          </Col>
        </Row>
        <Modal isOpen={unstake} toggle={() => setUnstake(!unstake)} style={{ marginTop: '20vh' }}>
          <DetailStake key={itemSelected && itemSelected.poolId.toString()}>
            <div className="item">
              <span className="h3 bold">Are you sure to unstake?</span>
            </div>
            <div>
              Note: If you withdraw before the redemption date, you will not receive the staking interest reward.
            </div>
            <div className="d-flex justify-content-around mt-4">
              <Button
                disabled={loading}
                style={{
                  background: `gold`,
                  color: 'black',
                  border: 0,
                  borderRadius: 0,
                  fontWeight: 500,
                  textAlign: 'center',
                }}
                onClick={() => itemSelected && Unstack(itemSelected.poolId)}
              >
                {loading ? (
                  <span className="mr-2">
                    <span className="mr-2">
                      <Loader />
                    </span>
                    Confirm
                  </span>
                ) : (
                  <span>Confirm</span>
                )}
              </Button>
              <Button
                style={{
                  background: ``,
                  color: 'black',
                  border: 0,
                  borderRadius: 0,
                  fontWeight: 500,
                  textAlign: 'center',
                }}
                onClick={() => setUnstake(false)}
              >
                Cancel
              </Button>
            </div>
          </DetailStake>
        </Modal>
      </>
    )
  }
  return (
    <Row>
      <Button
        style={{
          width: '40%',
          margin: 'auto',
          borderRadius: 0,
          background: '#ffd700',
          color: 'black',
          fontSize: 24,
        }}
        onClick={toggleWalletModal}
      >
        Connect wallet now
      </Button>
    </Row>
  )
}
